.wallet-page {
    font-weight: 900;
    min-height: 100vh;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
}

.wallet-page .break-before::before {
    content: '\a';
    white-space: pre;
}

.wallet-page--newbie {
    --primary-color: #9c035b;
    --secondary-color: #e42575;
    --primary-color-hover: #d00078;
    --secondary-color-hover: #ff3b8d;
}

.wallet-page--veteran {
    --primary-color: #007e47;
    --secondary-color: #00c06c;
    --primary-color-hover: #00a55d;
    --secondary-color-hover: #44ffae;
}

.wallet-page__logo {
    width: 100%;
    padding-top: 19.21%;
    background: no-repeat center/contain url('header__bg.png'), rgba(255, 255, 255, 0.1);
}

.wallet-page > header {
    padding: 2rem;
    text-align: center;
    white-space: nowrap;
}

.wallet-page > header > h1 {
    font-size: clamp(1.75rem, 1.5375rem + 1.0625vw, 2.8125rem);
    line-height: 1.2;
}

.wallet-page > header > h2 {
    font-size: clamp(1.2rem, 1.09rem + 0.55vw, 1.75rem);
    line-height: 1.2;
    padding-top: 1rem;
}

.wallet-page > header > h3 {
    font-size: clamp(0.8rem, 0.6475rem + 0.7625vw, 1.5625rem);
    padding-top: 1rem;
}

.wallet-page__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 1rem;
    min-width: 300px;
    max-width: 1440px;
    width: 100%;
    font-size: clamp(0.8rem, 0.5714rem + 1vw, 1.6rem);
}

.wallet-page__choice {
    display: grid;
    grid-template-columns: auto 5em;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5em;
    background-color: var(--primary-color);
    border: 7px solid var(--secondary-color);
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

.wallet-page__choice > div > h2 {
    font-size: 1em;
}

.wallet-page__choice > div > h3 {
    font-size: 0.8em;
}

.wallet-page__choice > figure {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
    height: 5em;
    width: 100%;
    overflow: hidden;
    display: flex;
    padding: 10%;
    flex-direction: column;
    justify-content: center;
}

.wallet-page__choice > figure > img {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    object-fit: contain;
}

.wallet-page__block {
    display: none;
}

#wallet-page__toggle:checked ~ .wallet-page__block {
    display: unset;
}

.wallet-page__block > header {
    padding-bottom: 1em;
    font-size: 0.8em;
}

.wallet-page__block > div {
    background-color: var(--primary-color);
    border: 7px solid var(--secondary-color);
    padding: 0.5rem;
}

.wallet-page__info-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.wallet-page__info {
    background-color: rgba(0, 0, 0, 0.35);
    padding: 1em;
}

.wallet-page__info > header {
    font-size: 0.8em;
}

.wallet-page__info > p {
    font-size: 0.7em;
    margin-top: 1em;
    color: rgba(255, 255, 255, 0.65);
}

.wallet-page__connect-wallet {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    white-space: nowrap;
    font-size: 0.8em;
}

.wallet-page__choose-wallet {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
}

.wallet-page__wallet-icon {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.wallet-page__wallet-icon[data-selected='true'] {
    background-color: rgba(0, 0, 0, 0.35);
}

.wallet-page__connect-button {
    background: linear-gradient(273deg, #101010 -25.82%, #555 122.59%);
    color: white;
    padding: 0.5rem 1rem;
    font-size: 0.8em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wallet-page__connect-button[disabled] {
    opacity: 0.5;
    cursor: unset;
}

.wallet-page__connect-button[data-connected='true'] {
    opacity: 1;
    background: rgba(0, 0, 0, 0.35);
}

.wallet-page__is-ledger {
    font-size: 0.8em;
    cursor: pointer;
}

.wallet-page__is-ledger:has(input:disabled) {
    opacity: 0.5;
}

#wallet-page__is-ledger__toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    font-size: inherit;
    width: 3em;
    height: 1em;
    box-sizing: content-box;
    vertical-align: text-bottom;
    margin: auto;
    color: inherit;
    background: #1c1c1c;
}

#wallet-page__is-ledger__toggle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    box-sizing: border-box;
    width: 1.4em;
    height: 1.4em;
    background: #373737;
}

#wallet-page__is-ledger__toggle:checked::before {
    left: 1.6em;
}

.wallet-page__lines.wallet-page--newbie {
    display: none;
    grid-column: 1 / 3;
    grid-row: 2 /3;
    border-right: 7px solid;
    border-color: var(--secondary-color);
    width: calc(57% - 0.5rem - 3em);
    height: 2.8em;
    margin-top: -1rem;
}

.wallet-page__lines.wallet-page--veteran {
    display: none;
    grid-column: 1 / 3;
    grid-row: 2 / 5;
    border-right: 7px solid;
    border-bottom: 7px solid;
    border-color: var(--secondary-color);
    width: calc(100% - 3em);
    height: calc(100% - 2em);
    margin-top: -1rem;
}

.wallet-page__skip-button {
    font-size: clamp(0.8rem, 0.5714rem + 1vw, 1.6rem);
    background: linear-gradient(273deg, #101010 -25.82%, #555 122.59%);
    padding: 1rem;
    margin-top: 2rem;
}

.wallet-page__skip-button:hover {
    background: linear-gradient(273deg, #c91fff -25.82%, #03ceff 122.59%);
}

@media screen and (min-width: 768px) {
    .wallet-page .break-before::before {
        content: none;
    }

    .wallet-page__grid {
        grid-template-columns: 57fr 43fr;
    }

    .wallet-page__grid:has(.wallet-page__choice.wallet-page--newbie:hover) {
        .wallet-page--newbie {
            --primary-color: var(--primary-color-hover);
            --secondary-color: var(--secondary-color-hover);
        }
    }

    .wallet-page__grid:has(.wallet-page__choice.wallet-page--veteran:hover) {
        .wallet-page--veteran {
            --primary-color: var(--primary-color-hover);
            --secondary-color: var(--secondary-color-hover);
        }
    }

    .wallet-page__choice.wallet-page--newbie {
        grid-column: 1;
        grid-row: 1;
    }

    .wallet-page__choice.wallet-page--veteran {
        grid-column: 2;
        grid-row: 1;
    }

    .wallet-page__block.wallet-page--newbie {
        grid-column: 1 / 3;
        grid-row: 2;
        width: 90%;
    }
    .wallet-page__block.wallet-page--veteran {
        grid-column: 1 / 3;
        grid-row: 3;
        width: min-content;
    }

    .wallet-page__connect-wallet {
        flex-direction: row;
    }

    #wallet-page__toggle:checked ~ .wallet-page__lines.wallet-page--newbie,
    #wallet-page__toggle:checked ~ .wallet-page__lines.wallet-page--veteran {
        display: unset;
    }
}
